// Google Recaptcha v3
grecaptcha.ready(function () {
	$('form.mod-recaptcha').each(function () {
		var object = $(this);
		grecaptcha.execute('6LemkrglAAAAAH84QCoYxiMpcFZ7O7tN9xLJkeMf', { action: 'homepage' }).then(function (token) {
			$('<input>').attr({
				type: 'hidden',
				name: 'g-recaptcha-response',
				value: token
			}).appendTo(object)
		});
	});
});

//hide cookie policy
$(".section-cookies .btn-accept").on("click", function (e) {
	e.preventDefault();

	// If no validation errors
	$.ajax({
		method: "POST",
		url: "api/cookies/",
		data: {
			'cookies-accepted': 1,
		}
	}).done(function (response) {
		$('.section-cookies').addClass('mod-hide').removeClass('show');
	});
});

$(".section-cookies .btn-decline").on("click", function (ev) {
	ev.preventDefault();

	$.ajax({
		method: "POST",
		url: "api/cookies/",
		data: {
			'cookies-accepted': 0,
		}
	}).done(function (response) {
		$('.section-cookies').addClass('mod-hide').removeClass('show');
	});
});

// Pops out share modal window
$(".social-share").click(function (e) {
	// Prevents default action
	e.preventDefault();
	// Runs window popup function
	windowPopup($(this).attr("href"), 500, 300);
});

// Ajax contact form processing
$('#contact #contact-form').submit(function (e) {
	e.preventDefault();

	// if honeypot field is filled in by spambot, do not submit
	if ($('input#Form_Email').val().length != 0) {
		return false;
	}

	$.ajax({
		method: 'POST',
		url: 'process/contact/',
		data: $(this).serialize()
	}).done(function (response) {

		// If no errors
		if (response == 'success') {
			// Reset form completely
			$('#contact #contact-form .form-element').removeClass('mod-filled');
			$('#contact #contact-form').hide().trigger('reset');

			// Show thank you message
			$('#contact .sub-thanks').fadeIn();

			// Remove any errors that may still be there
			$('#contact #contact-form .sub-errors').html('');

			// Hide thank you message after 10 seconds and return to form
			setTimeout(function () {
				$('#contact #contact-form').fadeIn();
				$('#contact .sub-thanks').hide();
			}, 10000);

		}
		else {
			console.log(response);
			$('#contact #contact-form .sub-errors').fadeIn().html(response);
		}
	});
});

// Ajax refer child form processing
$('#refer-child-form').submit(function (e) {
	e.preventDefault();

	// if honeypot field is filled in by spambot, do not submit
	if ($('input#Form_Email').val().length != 0) {
		return false;
	}

	$.ajax({
		method: 'POST',
		url: 'process/refer-child/',
		data: $(this).serialize()
	}).done(function (response) {

		// If no errors
		if (response == 'success') {
			// Reset form completely
			$('#refer-child-form .form-element').removeClass('mod-filled');
			$('#refer-child-form').hide().trigger('reset');

			// Show thank you message
			$('#refer-your-child .sub-thanks').fadeIn();

			// Remove any errors that may still be there
			$('#refer-child-form .sub-errors').html('');

			// Hide thank you message after 10 seconds and return to form
			setTimeout(function () {
				$('#refer-child-form').fadeIn();
				$('#refer-your-child .sub-thanks').hide();
			}, 10000);

		}
		else {
			console.log(response);
			$('#refer-child-form .sub-errors').fadeIn().html(response);
		}
	});
});

// Ajax refer child form processing
$('#subscribe-form').submit(function (e) {
	e.preventDefault();

	// if honeypot field is filled in by spambot, do not submit
	if ($('input#Form_Email').val().length != 0) {
		return false;
	}

	$.ajax({
		method: 'POST',
		url: 'process/subscribe/',
		data: $(this).serialize()
	}).done(function (response) {

		// If no errors
		if (response == 'success') {
			// Reset form completely
			$('#subscribe-form .form-element').removeClass('mod-filled');
			$('#subscribe-form').hide().trigger('reset');

			// Show thank you message
			$('#subscribe-to-our-newsletter .sub-thanks').fadeIn();

			// Remove any errors that may still be there
			$('#subscribe-form .sub-errors').html('');

			// Hide thank you message after 10 seconds and return to form
			setTimeout(function () {
				$('#subscribe-form').fadeIn();
				$('#subscribe-to-our-newsletter .sub-thanks').hide();
			}, 10000);

		}
		else {
			console.log(response);
			$('#subscribe-form .sub-errors').fadeIn().html(response);
		}
	});
});

// Ajax refer child form processing
$('#download-fundraiser-form').submit(function (e) {
	e.preventDefault();

	// if honeypot field is filled in by spambot, do not submit
	if ($('input#Form_Email').val().length != 0) {
		return false;
	}

	$.ajax({
		method: 'POST',
		url: 'process/download-form/',
		data: $(this).serialize()
	}).done(function (response) {

		// If no errors
		if (response == 'success') {
			// Reset form completely
			$('#download-fundraiser-form .form-element').removeClass('mod-filled');
			$('#download-fundraiser-form').hide().trigger('reset');

			// Show thank you message
			$('.section-download-form .sub-thanks').fadeIn();

			// Remove any errors that may still be there
			$('#download-fundraiser-form .sub-errors').html('');

			$('#download-fundraiser-form .sub-errors').removeClass('mod-active');

			// Hide thank you message after 10 seconds and return to form
			setTimeout(function () {
				$('#download-fundraiser-form').fadeIn();
				$('.section-download-form .sub-thanks').hide();
			}, 10000);

		}
		else {
			console.log(response);
			$('#download-fundraiser-form .sub-errors').addClass('mod-active').fadeIn().html(response);
		}
	});
});

// Add class to inputs when clicked on add effect

if ($('form').length) {
	$('form input, form textarea').focus(function () {
		$(this).parent().addClass('mod-filled');

	}).blur(function () {
		if ($(this).val()) {
			$(this).parent().addClass('mod-filled');
		} else {
			$(this).parent().removeClass('mod-filled');
		}
	});
}

// Google Maps

// If div with ID of map exists then run the function
if ($('#map').length) {
	function createMap(el, lat, long, zoom, marker) {
		// Set a center point of the map, can be re-evaluated using `centerMap()`
		const center = { lat: lat, lng: long };

		const mapOptions = {
			zoom: zoom,
			center: center,
			fullscreenControl: false,
		};

		// Create a map instance.
		const melonMap = new MelonMap(document.getElementById(el), mapOptions);

		// Marker locations [name, lat, long, zindex]
		const markerLocation = [['Footprints', lat, long, 1]];

		if (marker == true) {
			markerLocation.forEach((markerLocation) => {
				const title = markerLocation[0];

				const location = {
					lat: parseFloat(markerLocation[1]),
					lng: parseFloat(markerLocation[2])
				}

				melonMap.addMarker(location, title);
			});
		}

		// Add a map style
		melonMap.setMapStyle(
			[{ "featureType": "all", "elementType": "geometry.fill", "stylers": [{ "weight": "2.00" }] }, { "featureType": "all", "elementType": "geometry.stroke", "stylers": [{ "color": "#9c9c9c" }] }, { "featureType": "all", "elementType": "labels.text", "stylers": [{ "visibility": "on" }] }, { "featureType": "landscape", "elementType": "all", "stylers": [{ "color": "#f2f2f2" }] }, { "featureType": "landscape", "elementType": "geometry.fill", "stylers": [{ "color": "#ffffff" }] }, { "featureType": "landscape.man_made", "elementType": "geometry.fill", "stylers": [{ "color": "#ffffff" }] }, { "featureType": "poi", "elementType": "all", "stylers": [{ "visibility": "off" }] }, { "featureType": "road", "elementType": "all", "stylers": [{ "saturation": -100 }, { "lightness": 45 }] }, { "featureType": "road", "elementType": "geometry.fill", "stylers": [{ "color": "#eeeeee" }] }, { "featureType": "road", "elementType": "labels.text.fill", "stylers": [{ "color": "#7b7b7b" }] }, { "featureType": "road", "elementType": "labels.text.stroke", "stylers": [{ "color": "#ffffff" }] }, { "featureType": "road.highway", "elementType": "all", "stylers": [{ "visibility": "simplified" }] }, { "featureType": "road.arterial", "elementType": "labels.icon", "stylers": [{ "visibility": "off" }] }, { "featureType": "transit", "elementType": "all", "stylers": [{ "visibility": "off" }] }, { "featureType": "water", "elementType": "all", "stylers": [{ "color": "#46bcec" }, { "visibility": "on" }] }, { "featureType": "water", "elementType": "geometry.fill", "stylers": [{ "color": "#c8d7d4" }] }, { "featureType": "water", "elementType": "labels.text.fill", "stylers": [{ "color": "#070707" }] }, { "featureType": "water", "elementType": "labels.text.stroke", "stylers": [{ "color": "#ffffff" }] }],
			{ name: 'Styled Map' }
		);
	}

	// If the contact page then create the map with the following coordiantes
	if ($('#contact').length) {
		createMap('map', 52.9565629, -1.1171576, 15, true);
	}
}

// Function for popout window
function windowPopup(url, width, height) {
	// Calculate the position for the popup so it's centered on the screen.
	const left = (screen.width / 2) - (width / 2);
	const top = (screen.height / 2) - (height / 2);

	// opens a window with the given url & set position from above
	window.open(url, "", "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=" + width + ",height=" + height + ",top=" + top + ",left=" + left);
}

// Ensure the function exists on the page before we call it.
if (!!window['gdprSubscribeModal']) {
	// The form of which to attach the modal to.
	const formSelector = '#contact-form';
	// The url to link to for the privacy policy.
	const privacyPolicyURL = 'privacy-policy.html';
	// Color of the links in the modal
	const linkColour = '#000';
	// Color of the buttons in the modal.
	const buttonTextColour = '#000';

	// Call this per form.
	gdprSubscribeModal(formSelector, privacyPolicyURL, linkColour, buttonTextColour);
}

// Nav menu stuff

const registerDesktopNavMenuParentListeners = () => {
	const subMenuParents = document.querySelectorAll('.header-main-desktop .has-sub-menu, .header-main-mobile .has-sub-menu');

	subMenuParents.forEach((element) => {
		if (element.getAttribute('parent-menu-initialised') === 'true') {
			return;
		}

		// Ignore events on e.g. the sub menu itself.
		const isValidTarget = (event) => {
			if (event.target === element) {
				return true;
			}

			if (event.target.classList.contains('mod-nav-main-sublink-parent-btn') === true) {
				return true;
			}

			if (event.target.classList.contains('pink-chevron-down') === true) {
				return true;
			}

			return false;
		}

		const subMenu = element.querySelector('.list-sub-menu');

		element.addEventListener('mousedown', (event) => {
			event.preventDefault();

			if (isValidTarget(event) === false) {
				return;
			}

			element.classList.toggle('mod-open');

			if (element.classList.contains('mod-open') === true) {
				subMenu.focus();
			}

			if (element.classList.contains('mod-open') === true) {
				const siblings = element.parentElement.querySelectorAll('.has-sub-menu');

				// Close any sibling menus already open.
				siblings.forEach((parentMenu) => {
					if (parentMenu === element) {
						return;
					}

					parentMenu.classList.remove('mod-open');
				});
			}
		});

		element.addEventListener('keydown', (event) => {
			event.preventDefault();

			if (event.key === 'Escape') {
				element.classList.remove('mod-open');
			}

			if (isValidTarget(event) === false) {
				return;
			}

			// Enter or Space bar.
			if (event.key === 'Enter' || event.key === ' ') {
				element.classList.toggle('mod-open');

				if (element.classList.contains('mod-open') === true) {
					subMenu.focus();
				}

				// Close any sibling menus already open.
				element.parentElement.querySelectorAll('.has-sub-menu').forEach((parentMenu) => {
					if (parentMenu === element) {
						return;
					}

					parentMenu.classList.remove('mod-open');
				});
			}
		});

		// Click outside.
		subMenu.addEventListener('blur', (event) => {
			event.preventDefault();

			element.classList.remove('mod-open');
		});

		subMenu.querySelectorAll('a').forEach((subMenuAnchor) => {
			subMenuAnchor.addEventListener('keyup', (event) => {
				event.preventDefault();

				if (event.key === 'Escape') {
					element.classList.remove('mod-open');
				}
			});
		});

		element.setAttribute('parent-menu-initialised', 'true')
	});
}

const registerMobileNavMenuParentListeners = () => {
	const subMenuParents = document.querySelectorAll('.header-main-mobile .has-sub-menu');

	subMenuParents.forEach((element) => {
		if (element.getAttribute('parent-menu-initialised') === 'true') {
			return;
		}

		element.addEventListener('touchstart', (event) => {
			if (event.target.classList.contains('mod-nav-main-sublink-parent-btn') === false
				&& event.target.classList.contains('has-sub-menu') === false) {
				return;
			}

			event.preventDefault();

			element.classList.toggle('mod-open');
		});

		element.setAttribute('parent-menu-initialised', 'true')
	});
}

const styleTimeline = () => {
	const timelineSection = document.querySelector('.section-timeline');

	if (timelineSection === null) {
		return;
	}

	const timelineBar = timelineSection.querySelector('.timeline-bar');
	const timeline = timelineSection.querySelector('.timeline');
	const timelinePaddingTop = Number(window.getComputedStyle(timeline).paddingTop.replace('px', ''))
	const cards = timeline.querySelectorAll('.timeline-content .timeline-card');

	cards.forEach((element, index) => {
		const dot = document.createElement('div');
		dot.classList.add('js-dynamic-dot');
		dot.style.top = (element.offsetTop - timelinePaddingTop) + 'px';

		timelineBar.append(dot);

		// Make timeline bar end at the top of the final card.
		if (index === (cards.length - 1)) {
			const timelineBarHeight = Number(window.getComputedStyle(timelineBar).height.replace('px', ''));
			timelineBar.style.height = (timelineBarHeight - element.offsetHeight) + 'px';
		}
	});
}

const showDesktopLayout = window.outerWidth > 1024;

document.addEventListener('DOMContentLoaded', () => {
	registerDesktopNavMenuParentListeners();
	registerMobileNavMenuParentListeners();
	styleTimeline();

	// Slick

	// If on mobile layout, keep Slick enabled but disable being able to move it
	// in order to retain the same desktop structure while only showing the first
	// slide (as according to the design).
	$('.section-hero-image > .sub-section-hero-image-slide-container').slick({
		accessibility: showDesktopLayout,
		arrows: showDesktopLayout,
		dots: showDesktopLayout,
		draggable: showDesktopLayout,
		swipe: showDesktopLayout,
		touchMove: showDesktopLayout,
		fade: true
	});

	$('.testimonials-slide-container').slick({
		dots: true,
		arrows: false,
	});

	$('.section-content-and-quote .quote-slides-container').slick({
		dots: true,
		arrows: false,
	});

	$('.section-image-carousel .section-image-carousel-slide-container').slick({
		dots: true,
		arrows: false,
		slidesToShow: showDesktopLayout === true ? 5 : 1,
		slidesToScroll: showDesktopLayout === true ? 5 : 1,
	});

	// Mobile burger menu

	const body = document.querySelector('body');
	const mobileNav = document.querySelector('.header-main-mobile');
	const mobileBurgerButton = document.querySelector('.mod-burger-btn');
	mobileBurgerButton.addEventListener('mousedown', () => {
		mobileNav.classList.add('mod-open');
		body.classList.add('mod-overflow-hidden');
	});

	document.querySelector('.mod-close-mobile-menu-btn').addEventListener('mousedown', () => {
		mobileNav.classList.remove('mod-open');
		body.classList.remove('mod-overflow-hidden');
	});

	// Accordion

	document.querySelectorAll('.accordion-header').forEach(
		(item) => {
			item.addEventListener('click', (event) => {
				$(event.target).closest('.accordion-item')[0].classList.toggle('mod-accordion-item-closed');
			});
		}
	);

	if ($('#faqs').length) {
		$(".sub-accordion").accordionjs({
			activeIndex: false,
			closeOther: false,
		});
	}

	// Play video buttons

	document.querySelectorAll('.js-play-video-btn').forEach((element) => {
		element.addEventListener('click', (event) => {
			event.preventDefault();

			const videoContainer = document.querySelector('.section-video-modal .sub-video-container');

			// 			if (videoContainer === null) {
			// 				console.error(
			// 					'Play video button cannot be initialised without a .section-video-modal element on the page.'
			// 				);

			// 				return;
			// 			}

			// 			const href = element.getAttribute('href');

			// 			const videoHtml = `
			// <source src="${href}">
			// Your browser does not support the video tag.
			// `;
			// 			const video = document.createElement('video');
			// 			video.setAttribute('controls', '');
			// 			video.innerHTML = videoHtml;

			// 			const existingVideoElement = videoContainer.querySelector('video');
			// 			if (existingVideoElement !== null) {
			// 				existingVideoElement.replaceWith(video)
			// 			} else {
			// 				videoContainer.append(video);
			// 			}

			body.classList.add('mod-overflow-hidden');
			videoContainer.parentElement.classList.remove('mod-video-modal-section-hidden');
		});
	});

	document.querySelectorAll('.close-video-modal-btn').forEach((element) => {
		element.addEventListener('click', () => {
			body.classList.remove('mod-overflow-hidden');
			element.parentElement.parentElement.classList.add('mod-video-modal-section-hidden');
		});
	})

	// Download form

	document.querySelectorAll('.js-download-form-button').forEach((element) => {
		element.addEventListener('click', (event) => {
			event.preventDefault();

			const formContainer = document.querySelector('.section-download-form');

			if (formContainer === null) {
				console.error(
					'Download form button cannot be initialised without a .section-download-form element on the page.'
				);

				return;
			}

			body.classList.add('mod-overflow-hidden');
			formContainer.classList.remove('mod-download-form-section-hidden');
		});
	});

	document.querySelectorAll('.close-download-form-btn').forEach((element) => {
		element.addEventListener('click', () => {
			body.classList.remove('mod-overflow-hidden');
			element.parentElement.parentElement.classList.add('mod-download-form-section-hidden');
		});
	})

	// Timeline

	if ($('#our-history').length) {
		document.querySelectorAll('.js-read-more-button').forEach((element) => {
			element.addEventListener('click', () => {
				const content = element.parentElement.querySelector('.timeline-card-content');

				console.log('yes');

				if (content.classList.contains('mod-timeline-card-content-visible') === true) {
					content.classList.remove('mod-timeline-card-content-visible');
					element.innerHTML = 'Read more';

					return;
				}

				content.classList.add('mod-timeline-card-content-visible');
				element.innerHTML = 'Read less';
			});
		});
	}


	// Read More content

	if ($('#meet-the-team, #families-stories').length) {
		document.querySelectorAll('.js-read-more-button').forEach((element) => {
			element.addEventListener('click', (event) => {
				event.preventDefault();

				const modal = document.querySelector('.section-read-more-content-modal');

				if (modal === null) {
					console.error(
						'Download form button cannot be initialised without a .section-read-more-content-modal element on the page.'
					);

					return;
				}

				const contentContainer = document.querySelector('#' + element.getAttribute('data-content-id'));

				modal.querySelector('.content').innerHTML = contentContainer.innerHTML;

				body.classList.add('mod-overflow-hidden');
				modal.classList.remove('mod-read-more-content-modal-hidden');
			});
		});

		document.querySelectorAll('.close-read-more-content-modal-btn').forEach((element) => {
			element.addEventListener('click', () => {
				body.classList.remove('mod-overflow-hidden');
				element.parentElement.parentElement.classList.add('mod-read-more-content-modal-hidden');
			});
		});
	}

	if ($('form').length) {
		const subscribe = document.getElementById('subscribe');

		subscribe.addEventListener('change', function () {
			if (subscribe.checked == true) {
				subscribe.value = 1;
			} else {
				subscribe.value = 0;
			}
		})
	}
});
